import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'
import {
  useRoute,
  useRouter
} from 'vue-router'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import TableDeliveryPending from '@/views/transactions/delivery-item/delivery-pending/components/TableDeliveryPending.vue'

export default {
  components: {
    TableDeliveryPending
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const op = ref()
    const Id = ref(null) as any
    const route = useRoute()
    const inputSearch = ref(null)
    const refTableDeliveryPending = ref('') as any
    const filters = {
      search: '',
      filter: 'statusPengirimanId eq 2',
      expand: '&$expand=TransPengirimanDetails($count=true;$select=id), AgenAsal($select=Id,Nama), AgenTujuan($select=Id,Nama)',
      select: '&$select=Id, PelangganId, ManifestId, TransJadwalHdId, JenisPembayaranId, TransPenerimaanId, AgenAsalId, AgenTujuanId, Resi',
      totalRecords: 0,
      top: 0,
      skip: 0
    }
    const isRefund = ref(false)
    const data = ref([])
    const showDialogDetail = ref(false)
    const dataForm = ref({})

    const Search = (val: any) => {
      refTableDeliveryPending.value.Search(val)
      // filters.search = val
      // getAllData()
    }

    const onDetail = (val: any) => {
      router.push({
        name: 'transaksi-pengiriman-pending-detail',
        params: {
          id: val
        }
      })
    }
    return {
      store,
      filters,
      data,
      inputSearch,
      moment,
      onDetail,
      router,
      route,
      Search,
      showDialogDetail,
      op,
      isRefund,
      dataForm,
      Id,
      refTableDeliveryPending
    }
  }
}
